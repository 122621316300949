import React from "react";
import PageBanner from "../../components/PageBanner";
import { Link } from "react-router-dom";
import useScrollUp from "../../utilities/useScrollUp";
import MailToLink from "../../components/MailToLink";
import styled from 'styled-components';
import WolfmanStatement from "../../components/WolfmanStatement";



function Sponsors () {

  useScrollUp()
  return (
  <React.Fragment>
    <PageBanner heading="Show Sponsors" tagline="Thank you sponsors!" backgroundImage="../../images/sponsors/sponsorsBanner.png"/>
    <div className="container">
      <h1>Sponsors</h1>
      <p>So much of what we do relies on our generous sponsors.</p>
      <div className="col-12 center">
      <h3>Become a Sponsor!</h3>
      <p><MailToLink label="Email Us" mailto="mailto:info@americangraffiti.net" /></p>
      </div>
      <section>
      <Wrapper className="card">
        <div className="row no-gutters">
          <div className="col-4">
            <img className="card-img" src="../images/sponsors/capitol_logo.png" alt="The Freshman Hop Salute Partner"/>
          </div>
          <div className="col-8">
            <div className="card-body">
              <h3 className="card-title">Signature Sponsor</h3>
              <ul className="card-text">
                <li><Link to={{ pathname: "http://www.capitolelectric.com/"}} target="_new">Capital Electric</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper className="card">
        <div className="row no-gutters">
          <div className="col-4">
            <img className="card-img" src="../images/sponsors/hop.jpeg" alt="The Freshman Hop Salute Partner"/>
          </div>
          <div className="col-8">
            <div className="card-body">
              <h3 className="card-title">The Freshman Hop Salute Partner</h3>
              <ul className="card-text">
                <li><Link to={{ pathname: "https://www.facebook.com/brian.azevedo.92"}} target="_new">Azevedo Autorepair</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper className="card">
        <div className="row no-gutters">
          <div className="col-4">
            <img className="card-img" src="../images/sponsors/peelout.jpeg" alt="Peel Out Salute Partner"/>
          </div>
          <div className="col-8">
            <div className="card-body">
              <h3 className="card-title">Peel Out Salute Partner</h3>
              <ul className="card-text">
                <li><Link to={{ pathname: "https://antonisconstruction.com/"}} target="_new">Antonis Construction</Link></li>
                <li><Link to={{ pathname: "https://gulicksautobody.com/"}} target="_new">Gulick's Autobody</Link></li>
                <li><Link to={{ pathname: "https://www.jscole.com/"}} target="_new">JS Cole Equipment</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper className="card">
        <div className="row no-gutters">
          <div className="col-4">
            <img className="card-img" src="../images/sponsors/walking.jpeg" alt="Someone Roaming the Streets Wants Me"/>
          </div>
          <div className="col-8">
            <div className="card-body">
              <h3 className="card-title">Someone Roaming the Streets Wants Me</h3>
              <ul className="card-text">
                <li><Link to={{ pathname: "https://dunawaypaints.com/"}} target="_new">Dunaway Paint</Link></li>
                <li><Link to={{ pathname: "https://www.facebook.com/profile.php?id=100064111446062"}} target="_new">Insignia Branding</Link></li>
                <li><Link to={{ pathname: "https://www.petaluma.minutemanpress.com/"}} target="_new">Minteman Press</Link></li>
                <li><Link to={{ pathname: "https://www.napaonline.com/stores/ca/petaluma/32-4th-st"}} target="_new">Napa Auto Parts Petaluma</Link></li>
                <li>Yelton Company</li>
              </ul>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper className="card">
        <div className="row no-gutters">
          <div className="col-4">
            <img className="card-img" src="../images/sponsors/paradise.jpg" alt="Paradise Road"/>
          </div>
          <div className="col-8">
            <div className="card-body">
              <h3 className="card-title">Paradise Road</h3>
              <ul className="card-text">
                <li><Link to={{ pathname: "https://www.arolo.com/"}} target="_new">Arolo Company, Inc.</Link></li>
                <li>Jeff Ceresa & Son Construction</li>
                <li><Link to={{ pathname: "https://www.winecountryqi.com/"}} target="_new">Quality Inn</Link></li>
                <li>Wildcat Construction</li>
              </ul>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper className="card">
        <div className="row no-gutters">
          <div className="col-4">
            <img className="card-img" src="../images/sponsors/wolfman.jpeg" alt="The Wolfman"/>
          </div>
          <div className="col-8">
            <div className="card-body">
              <h3 className="card-title">The Wolfman</h3>
              <ul className="card-text">
                <li><Link to={{ pathname: "https://www.groceryoutlet.com/"}} target="_new">Grocery Outlet</Link></li>
                <li><Link to={{ pathname: "https://www.gohansel.com/"}} target="_new">Hansel Auto Group</Link></li>
                <li><Link to={{ pathname: "https://www.northbaycraftsmen.com/"}} target="_new">Northbay Craftsman Inc</Link></li>
                <li><Link to={{ pathname: "https://www.petalumamarket.com/"}} target="_new">Petaluma Market</Link></li>
                <li>Plaza North Shopping Center</li>
                <li><Link to={{ pathname: "https://redhawkglass.com/"}} target="_new">Redhawk Glass</Link></li>
                <li><Link to={{ pathname: "https://www.vanbebbersteel.com/"}} target="_new">Van Bebber Bros.</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper className="card">
        <div className="row no-gutters">
          <div className="col-4">
            <img className="card-img" src="../images/sponsors/herby.jpeg" alt="Herbie & the Heartbeats"/>
          </div>
          <div className="col-8">
            <div className="card-body">
              <h3 className="card-title">Herbie & the Heartbeats</h3>
              <ul className="card-text">
                <li><Link to={{ pathname: "http://www.acculine.net/"}} target="_new">Accu-line</Link></li>
                <li><Link to={{ pathname: "https://www.bkbheating.com/"}} target="_new">BKB Heating & A/C</Link></li>
                <li><Link to={{ pathname: "https://www.brodiestireandautomotive.com/"}} target="_new">Brodie Tire & Brake Service</Link></li>
                <li><Link to={{ pathname: "https://empireautomotive.net/"}} target="_new">Empire Automotive</Link></li>
                <li><Link to={{ pathname: "https://lacehouselinen.com/"}} target="_new">Lace House Linen Supply, Inc</Link></li>
                <li><Link to={{ pathname: "https://www.poppy.bank/location/poppy-bank-petaluma-branch/"}} target="_new">Poppy Bank</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper className="card">
        <div className="row no-gutters">
          <div className="col-4">
            <img className="card-img" src="../images/sponsors/falfa.jpeg" alt="Falfa’s ’55 Chevy"/>
          </div>
          <div className="col-8">
            <div className="card-body">
              <h3 className="card-title">Falfa’s ’55 Chevy</h3>
              <ul className="card-text">
                <li><Link to={{ pathname: "https://blvd-automotive.com/"}} target="_new">Boulevard Automotive</Link></li>
                <li>Jay & Bill's Tire Service</li>
                <li><Link to={{ pathname: "http://www.outwestgarage.com/"}} target="_new">Outwest Garage</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper className="card">
        <div className="row no-gutters">
          <div className="col-4">
            <img className="card-img" src="../images/sponsors/impala.jpg" alt="Steve's '58 Impala"/>
          </div>
          <div className="col-8">
            <div className="card-body">
              <h3 className="card-title">Steve's '58 Impala</h3>
              <ul className="card-text">
              <li>Bill & Jay's</li>
              <li><Link to={{ pathname: "https://cruisinnorth.com/"}} target="_new">Cruisin' North Car Club</Link></li>
              <li><Link to={{ pathname: "https://www.summitstatebank.com/"}} target="_new">Summit State Bank</Link></li>
              <li><Link to={{ pathname: "https://www.statefarm.com/"}} target="_new">Tony Maestri State Farm Insurance</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper className="card">
        <div className="row no-gutters">
          <div className="col-4">
            <img className="card-img" src="../images/sponsors/edsel.png" alt="Laurie's '58 Edsel"/>
          </div>
          <div className="col-8">
            <div className="card-body">
              <h3 className="card-title">Laurie's '58 Edsel</h3>
              <ul className="card-text">
                <li>Bosh Automotive</li>
                <li>Breen Wealth Management</li>
                <li>Local Heroes Auto</li>
                <li>Morgan Manufacturing</li>
                <li>Norms Auto Machine Shop</li>
                <li>Northbay Mustang</li>
              </ul>
            </div>
          </div>
        </div>
      </Wrapper>
        
      <hr/>
      <div className="row">
        <div className="col-sm-2">
          <h4>Trophy Sponsors</h4>
        </div>
        <div className="col-sm-5">
          <ul>
            <li>CTB Car Club</li>
            <li>DeCarli Family</li>
            <li>Palmgren's</li>
            <li>WJBJ Cruisin' with the Classics</li>
          </ul>
        </div>
        <div className="col-sm-5">
          <ul>
            
          </ul>
        </div>
      </div>
      {/* <hr/>
      <div className="col-12">
        <h4>In-Kind ~ Merchandise ~ Other Sponsors & Contributor</h4>
        <p>These are the businesses and people who have donated an item for our raffle or donated their time and material to help make our event successful.  Thank You to all of you!</p>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <ul>
            <li>Safeway</li>
            
          </ul>
        </div>
        <div className="col-sm-4">
          <ul>
            <li>Wildcat Construction</li>
            
          </ul>
        </div>
        <div className="col-sm-4">
          <ul>
            
          </ul>
        </div>
      </div>*/}

      </section>
    </div>
    <WolfmanStatement/>
    </React.Fragment>
  );
}

const Wrapper = styled.div`
  margin 1% 0;
`
export default Sponsors;