import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

function FeaturedSponsors (props) {
  return(
    <SponsorGallery>
        <h2>Featured Event Sponsers</h2>
        <div className="container">
            <div className="row">
                <div className="col-3">
                    <Link to={{ pathname: "http://www.capitolelectric.com/"}} target="_new"><img src="../images/welcome/sponsers/capitol_electric.png" alt="Capitol Electric"/></Link>
                </div>
                <div className="col-3">
                    <Link to={{ pathname: "https://www.facebook.com/brian.azevedo.92"}} target="_new"><img src="../images/welcome/sponsers/AAlogo.png" alt="Azevedo Autorepair"/></Link>
                </div>
                <div className="col-3">
                    <Link to={{ pathname: "https://antonisconstruction.com/"}} target="_new"><img src="../images/welcome/sponsers/antonis.png" alt="Antonis Construction"/></Link>
                </div>
                <div className="col-3">
                    <Link to={{ pathname: "https://gulicksautobody.com/"}} target="_new"><img src="../images/welcome/sponsers/gulicks_logo.png" alt="Gulicks Body Shop"/></Link>
                </div>
            </div>
            <br/>
            <div className="row">                
                <div className="col-3">
                    <Link to={{ pathname: "https://www.jscole.com/"}} target="_new"><img src="../images/welcome/sponsers/js_cole.png" alt="JS Cole Equipment Rental"/></Link>                    
                </div>
                <div className="col-3">
                    <Link to={{ pathname: "https://dunawaypaints.com/"}} target="_new"><img src="../images/welcome/sponsers/dunaway_paints.png" alt="Dunaway Paints"/></Link>
                </div>
                <div className="col-3">
                    <Link to={{ pathname: "https://www.facebook.com/profile.php?id=100064111446062"}} target="_new"><img src="../images/welcome/sponsers/insignia.jpg" alt="Insignia Branding"/></Link>
                </div>
                <div className="col-3">
                    <Link to={{ pathname: "https://www.petaluma.minutemanpress.com/"}} target="_new"><img src="../images/welcome/sponsers/minutemanLogo.png" alt="Minuteman Press"/></Link>
                </div>
            </div>
            <br/>
            <div className="row"> 
                <div className="col-3">
                    <Link to={{ pathname: "https://www.napaonline.com/en/ca/petaluma/store/26613"}} target="_new"><img src="./images/welcome/sponsers/napa.jpeg" alt="Napa Auto Parts Petaluma"/></Link>
                </div>
                <div className="col-3">
                    <img src="./images/welcome/sponsers/yelton.png" alt="Yelton Company"/>
                    
                </div>
            </div>
        </div>            
    </SponsorGallery>
   );
}

const SponsorGallery =styled.section `
  margin-top: 5%;
  padding:2vh 0vh;
  color: #1A2930;
`

export default FeaturedSponsors;