import React from "react";
import styled from 'styled-components';


function MidPageBanner(props) {
  return (
    <SectionBanner style={{ backgroundImage: `url(${props.backgroundImage})` }}>  
      <div className="container">
        <Heading>{props.heading}</Heading>
        <Tagline>{props.tagline}</Tagline>
        
      </div>
    </SectionBanner>
    
  );
}

const SectionBanner = styled.div`
  padding: 10% 0;
  border-radius: 0;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 70vh;
  width: 100%;
  color: #fff;

  @media (max-width: 1024px) {
    height: 40vh;
  }
  @media (max-width: 767.98px) {
    height: 30vh;
  }
  @media (max-width: 575.98px) {
    height: 20vh;
  }
  @media (max-width: 376px) {
    height: 20vh;
  }
`;

const Heading = styled.h1 `
  text-shadow: 2px 2px 5px #000;
  font-weight: 900;
  font-size:4vw;

  @media (max-width: 575.98px) {
    display:none;
  }
`

const Tagline = styled.h2`
  text-shadow: 2px 2px 5px #000;
  font-weight: 600;
  font-size:2vw;

  @media (max-width: 575.98px) {
    display:none;
  }
`
export default MidPageBanner;