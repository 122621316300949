import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MidPageBanner from "../components/MidPageBanner";

function FeaturedHotel (props) {
  return(
    <HotelGallery>
    <h2>Where to Stay</h2>
    <Link to={{ pathname: "https://www.winecountryqi.com/"}} target="_new">
      <MidPageBanner heading="Quality Inn Petaluma" tagline="Host Hotel for Crusin' the Boulevard Central to all Event Locations" backgroundImage="images/welcome/hotel/qualityInnBanner.jpeg"/>
      </Link>
      
        <p>If you are considering visiting Petaluma for the Salute to American Graffiti Events or for another occation Cruisin' the Boulevard recomends staying at the Quality Inn.
        </p>
        <p>
        Historic Victorian architecture, slow winding rivers and scenic landscape make Sonoma County a charming getaway destination any time of year. Discover rest and relaxation amongst the colorful rolling hills and wineries of the Sonoma Valley. Our hotel is conveniently located near the historic downtown area and just minutes from numerous vineyards, coastal parks, golf courses, casinos, shops and restaurants.

        </p>
        <p><Link to={{ pathname: "https://www.winecountryqi.com/"}} target="_new">Click here to book your next stay at Quality Inn Petaluma</Link></p>
        <div className="container">
            
        </div>            
    </HotelGallery>
   );
}

const HotelGallery =styled.section `
  margin-top: 5%;
  padding:2vh 0vh;
  color: #1A2930;
`

export default FeaturedHotel;